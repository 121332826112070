@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700;800&display=swap");

.powerforrent-container {
  position: relative;
  width: 360px;
  height: 302px;
}

.powerforrent-contact-text {
  font-family: "Work Sans";
  font-weight: 800;
  font-size: 9px;
  width: auto;
  height: auto;
  position: absolute;
  top: 18px;
  left: 287px;
  color: #000000;
  z-index: 2;
}

.powerforrent-email-text {
  font-family: "Work Sans";
  font-weight: 700;
  font-size: 9px;
  width: auto;
  height: auto;
  position: absolute;
  top: 40px;
  left: 228px;
  color: #000000;
  z-index: 2;
}

.powerforrent-phone1-text {
  font-family: "Work Sans";
  font-weight: 700;
  font-size: 10px;
  width: auto;
  height: auto;
  position: absolute;
  top: 48px;
  left: 293px;
  color: #e77129;
  z-index: 2;
}

.powerforrent-phone2-text {
  font-family: "Work Sans";
  font-weight: 700;
  font-size: 10px;
  width: auto;
  height: auto;
  position: absolute;
  top: 56px;
  left: 276px;
  color: #e77129;
  z-index: 2;
}

.powerforrent-title-text {
  font-family: "Work Sans";
  font-weight: 800;
  font-size: 18px;
  color: #000000;
  line-height: 0.8;
  width: auto;
  height: auto;
  position: absolute;
  top: 106px;
  left: 226px;
  z-index: 2;
}

.powerforrent-subtitle-text {
  font-family: "Work Sans";
  font-weight: 700;
  font-size: 11px;
  color: #000000;
  text-align: left;
  line-height: 1.1;
  width: auto;
  height: auto;
  position: absolute;
  top: 161px;
  left: 239px;
  z-index: 2;
}

.powerforrent-footer-text {
  font-family: "Work Sans";
  font-weight: 700;
  font-size: 10px;
  color: #000000;
  width: 350px;
  height: auto;
  position: absolute;
  top: 270px;
  left: 6px;
  text-align: center;
  z-index: 2;
}

.powerforrent-img {
  width: 360px;
  height: 302px;
  object-fit: fill;
  z-index: 1;
}
