.postboard-container {
  width: 360px;
  height: auto;
}

.postboard-frame {
  width: 360px;
  height: 302px;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.postboard-frame::-webkit-scrollbar {
  display: none;
}
