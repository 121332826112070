.apc-checkbox-container{
    padding: 2px !important;
    cursor: pointer;
}

.apc-checkbox-container:hover{
    font-weight: bold;
    cursor: pointer;
}

.apc-textbox-container{
    padding: 0px !important;
}

.apc-textbox{
    padding: 1px !important;
    width: 45px !important;
    border-color: rgb(129, 129, 129);
}

.apc-categories-container{
   margin-top: .8rem;
   width:340px;
   height:180px;
   margin-left: .7rem;
   padding: 1px !important; 
}

.apc-categories-header{
    font-size: 1.1rem;

}