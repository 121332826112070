@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap');
.footer-wrapper{
    display: grid;
    width: 360px;
    grid-template-columns: auto;
    margin-left: auto;
    margin-right: auto;
}

.footer-container {
    font-family: 'Open Sans Condensed', sans-serif;
    width: 360px;
    text-align: center;
  }

.footer-data{
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgb(179, 176, 176);
    padding: 3px;
}

.company-name{
    font-size: 1.2rem;
    font-weight: 700;
    border-bottom-style: none;
}

.other-data{
    font-size: 0.9rem; 
}