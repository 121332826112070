@import url("https://fonts.googleapis.com/css2?family=Karantina:wght@300;400&family=Marcellus&family=Work+Sans:wght@100&display=swap");
.product-card-container {
  width: 360px;
  height: 604px;
  position: relative;
  background-color: #41475a;
}

.card-container {
  position: absolute;
  left: 35px;
  top: 44px;
  width: 290px;
  height: 516px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 1px 2px 5px #292c34;
}

.top-card-photo-container {
  position: relative;
  width: 290px;
  height: 271px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.product-img {
  z-index: 2;
  position: relative;
  top: 0px;
  left: 0px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 290px;
  height: 271px;
  object-fit: fill;
}

.bottom-card-details-container {
  position: relative;
  width: 290px;
  height: 245px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #e77129;
  color: #ffffff;

  text-align: center;
}

.productcardv2-price-container {
  position: absolute;
  top: 246px;
  left: 47px;
  width: 197px;
  height: 49px;
  border-radius: 10px;
  border-width: 1;
  border-style: solid;
  border-color: #e9572b;
  /*background-color: #facf61;*/
  background-image: linear-gradient(to right, #facf61, #fdebb7);
  box-shadow: 1px 2px 5px;
  z-index: 2;
  text-align: center;
}

.price-text {
  font-family: "Karantina";
  font-size: 48px;
  color: #000000;
  margin: auto;
  top: -18px;
  width: auto;
  position: relative;
}

.extgst-text {
  font-family: "Karantina";
  font-size: 24px;
  font-weight: 100;
  color: #000000;
  margin: auto;
  width: 41px;
  position: absolute;
  top: 5px;
  right: 1px;
}

.title-text {
  font-family: "Marcellus";
  font-size: 18px;
  width: 277px;
  height: auto;
  position: absolute;
  top: 39px;
  left: 6px;
  line-height: 1;
}

.subtitle-text {
  font-family: "Work Sans";
  font-weight: 100;
  font-size: 18px;
  width: 240px;
  height: 130px;
  position: absolute;
  top: 105px;
  left: 25px;
  overflow-y: hidden;
  line-height: 1.2;
}
