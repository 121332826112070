@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karantina:wght@300;400&family=Marcellus&family=Work+Sans:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@800&family=Karantina:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap);
.container-app {
  /*height: 100%;*/
  width: 360px;
  margin-left: auto;
  margin-right: auto;
  /* display: flex;
  flex-flow: column; */
}

.container-body {
  background: radial-gradient(#e5e5e5,#ffffff, #888585);
  width: 100%;
  height: 100%;
  padding: 0 0 0 0;
  /* overflow:auto; */
}

.container-footer {
  /*display: inline-flexbox;*/
  background-color:rgb(221, 217, 216);
  min-height: 9rem;
  width: 100%;
  padding: 10px 0 0 0;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #fa923f rgba(0,0,0,0.5);
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 9px;
}
*::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.5);
}
*::-webkit-scrollbar-thumb {
  background-color: #fa923f;
  border: 1px solid rgba(0,0,0,0.5);
}
.toolbar {
  width: 100%;
  /*position: fixed;*/
  background-color: #1c2126;
  /*position: -webkit-sticky; 
  position: sticky;*/
  background-color: #353c4a;
  top: 90px;
  top: 0;
  left: 0;
  box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
}

.toolbar .sticky-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.filler {
  flex: 1 1;
}

.spacer-20 {
  width: 20px;
}

.apc-sticky{
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  width: 100%;
  height: 70px !important;
  z-index: 100;
}

.apc-custom{
  width: 100%;
  height: 70px !important;
  background-color: #353c4a !important;
  display: flex;
  align-items: center;  
}

.apc-custom a {
    color: white;
    text-decoration: none;
  }

  .apc-custom a:hover,
  .apc-custom a:active {
    color: #fa923f;
    /* font-size: 105%; */
}

.nav-item {
    position: relative;
    display: block;
    width: auto;
    text-align: center;
    cursor: pointer;
    height:auto;   
}

.dropdown {
    position: relative;
    display: block;
    width: auto;
    text-align: center;
    cursor: pointer;  
}

.custom-underline,
.custom-underline:hover,
.custom-underline:focus,
.custom-underline:active {
  color: #ed6b21;
  /*color: #000000;*/
  text-decoration: none;
}

.custom-underline::after {
  content: "";
  position:absolute;
}

.nav-item .custom-underline::after {
    top: 95%;
    height: 2px;
    width: 0%;
    left: 5%;
    background-color: #ed6b21;
      transition: 0.2s ease all .2s;
  }
  
  .nav-item:hover .custom-underline::after {
    width: 90%;
      transition: 0.2s ease all;
  }

  .primary.dropdown-toggle{
      background-color: transparent !important;
      border: 0;
  }

  .dropdown-menu.show{
    /*background-color:#394868;*/
    background-color:#0062cc;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
  }
/*244381*/
  .dropdown-toggle.btn.btn-primary {
      background-color: transparent !important;
      box-shadow: none !important;
      border-style: solid;
      border-color: transparent;
      border-radius: 0%;
      text-transform: none !important;
  }

  .dropdown-toggle.btn.btn-primary:hover {
    background-color: transparent !important;
    color: #fa923f;
    /* font-size: 105%; */
    border-bottom-color:  #fa923f;
    border-bottom-width: 2px;
}

/*.navbar .apc-custom .nav-item .dropdown-menu .dropdown-item:hover { 
    background-color: #353c4a;
}*/
.dropdown-item{
  color: antiquewhite !important;
 font-size: 1rem !important; 
}

.dropdown-item:hover { 
    background-color: #353c4a !important;
    color: #fa923f !important;
}


.toggle_button {
  height: 24px;
  width: 30px;
  display: flex;
  justify-content: space-around;
  background: transparent !important;
  border: none;
  cursor: pointer;
  flex-direction: column;
  box-sizing: border-box;
}

.toggle_button:hover
{
      transform: scale(1.1);
}

.toggle_button:focus {
  outline: none;
}

.toggle_button_line {
  width: 30px;
  height: 2px;
  background: white;
}

.toggle_button_line:hover
{
      transform: scale(1.1);
}





.toolbar_container {
  width: 100%;
  /*position: fixed;*/
  /*display: inline-flexbox;*/
  /*background-color: #1c2126;*/
  background-color: #353c4a;
  top: 0;
  left: 0;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  flex: 1 1;
}

.toolbar {
  width: 100%;
  position: fixed;
  /*background-color: #1c2126;*/
  background-color: #353c4a;
  top: 90px;
  left: 0;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
}

.toolbar-top {
  background-color: #282c34;
  width: 100%;
  top: 0;
  left: 0;
  height: 90px;
  display: inline-flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.toolbartop_box {
  display: inline-flex;
  align-items: center;
  height: 90px;
  padding: 0 2rem;
}

/*.toolbar-top {
    background-color: #282c34;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 90px;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

.toolbartop_box{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 2rem
}
*/

.toolbartop_box_contact {
  background-color: #ed6b21;
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 0 10px;
  flex-direction: column;
  justify-content: right;
}

.top-toolbar-logoContainer {
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 0 10px;
}

.compcontact-bold {
  vertical-align: middle;
  font-family: Open Sans, sans-serif;
  font-size: 20px;
  font-weight: 550;
  text-transform: inherit;
  /*color: #282c34;*/
  color: #ffffff;
  margin-top: 10px;
  text-align: left;
  line-height: 15px;
  list-style-type: none;
}

.compcontact-narrow {
  vertical-align: middle;
  font-family: Open Sans, sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: inherit;
  color: #282c34;
  text-align: right;
  line-height: 15px;
  list-style-type: none;
  margin-top: 0px;
}

.spiel {
  vertical-align: middle;
  font-family: Open Sans, sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-transform: inherit;
  color: whitesmoke;
  text-align: center;
  line-height: 20px;
  list-style-type: none;
  margin-top: 10px;
}

.boltseparator {
  height: 90px;
  width: auto;
  margin: 0 auto !important;
  padding: 0px;
}

.spacer {
  flex: 1 1;
}

.toolbar_navigation {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

.toolbar_logo {
  margin-left: 1rem;
  padding: 0 1rem;
}

.toolbar_logo_image {
  width: 90%;
  height: 90%;
  /*animation: rotation 5s infinite linear;*/
}

@-webkit-keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}

.toolbar_logo a {
  color: #ed6b21;
  font-weight: 450;
  text-decoration: none;
  font-size: 1.2rem;
}

.toolbar_logo a:hover {
  color: #ffffff;
  font-weight: 470;
  width: 40px;
}

.toolbar_navigation_items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation_items li {
  padding: 0 2rem;
}

.box-e {
  position: relative;
  display: block;
  width: auto;
  text-align: center;
  cursor: pointer;
  height: 90px;
  line-height: 90px;
}

.cutom-nav-mobile-icon {
  font-size: 30px;
}

.custom-underline,
.custom-underline:hover,
.custom-underline:focus,
.custom-underline:active {
  color: #ed6b21;
  text-decoration: none;
}

.custom-underline::after {
  content: "";
  position: absolute;
}

.box-e .custom-underline::after {
  top: 65%;
  height: 2px;
  width: 0%;
  left: 5%;
  background-color: #ed6b21;
  transition: 0.2s ease all 0.2s;
}

.box-e:hover .custom-underline::after {
  width: 90%;
  transition: 0.2s ease all;
}

.toolbar_navigation_items li.with-submenu {
  padding: 0 2rem;
  color: white;
  text-decoration: none;
}

.toolbar_navigation_items a {
  color: white;
  text-decoration: none;
}

.toolbar_navigation_items a:hover,
.toolbar_navigation_items a:active {
  color: #fa923f;
  font-size: 105%;
}

.toolbar_navigation_items.menu {
  color: white;
  text-decoration: none;
}

.toolbar_navigation_items.menu:hover,
.toolbar_navigation_items.menu:active {
  color: #fa923f;
}

.toggle_button {
  height: 24px;
  width: 30px;
  display: flex;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  flex-direction: column;
  box-sizing: border-box;
}

.toggle_button:hover {
  transform: scale(1.1);
}

.toggle_button:focus {
  outline: none;
}

.toggle_button_line {
  width: 30px;
  height: 2px;
  background: white;
}

.toggle_button_line:hover {
  transform: scale(1.1);
}

.buyerschoice_href {
  display: block;
}

.buyreschoice_badge {
  background: url(https://d3cl59gmk6jgfj.cloudfront.net/images/widgetm4uaward20.png)
    no-repeat 0 0;
  height: 106px;
  width: 227px;
}

.buyerschoice_compnamecontainer {
  position: relative;
  top: 35px;
  left: 135px;
}

.buyerschoice_span {
  color: #fff;
  position: relative;
  width: 82px;
  font-size: 9px;
  height: 32px;
  display: table-cell;
  vertical-align: middle;
  font-family: Helvetica;
}

.section {
    padding-top: 3rem;
    display: block;
}

.is-medium {
    padding: 3rem 0.5rem;
}

.aboutus-content-wrapper{
    width: 360px;
    margin-right: auto;
    margin-left:auto;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
       -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
            text-size-adjust: 100%;
}

.aboutus-header-text{
    color: rgb(126, 125, 125);
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 2em;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
}

.aboutus-content-text{
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 1.5em;
    margin-left: 4rem;
    margin-right: 2rem;
}

.emphasize{
    font-weight: bold;
    margin-left: 1rem;
}

.footer{
    font-style: italic;
    margin-left: 1rem;
}

.aboutus-specialise-text{
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 1.5em;
    margin-left: 1rem;
    margin-right: 1rem;
    color: rgb(84, 85, 85);
}

.specialise-card{
    margin-right: 2rem !important;
}

.aboutus-2column{
    display: grid;
    grid-template-columns: auto;
}

.apc-logo-spec{
    padding-left: 5px;
    margin-left: 1rem;
    width: 29rem;
    max-height: 40rem;
    height: auto;
}

.aboutus-img-container{
    margin-left: 4rem;

}

.aboutus-img{
    width: 250px;
    height: 148px;
}

.aboutus-img-wide{
    width: 300px;
    height: auto;
}

.aboutus-img-wider{
    width: 360px;
    height: auto;
}

.history-pics-01{
    margin-top: 3.1rem;
    margin-right: -1rem;
    transform: rotate(-15deg);
    width: 250px;
    height: 148px;
    /*height: 296px;*/
    box-shadow: 5px 3px 10px grey;
}

.history-pics-02{
    margin-top: 2rem;
    margin-right: -1rem;
    transform: rotate(5deg);
    width: 250px;
    height: 148px;
    box-shadow: 5px 3px 10px grey;
}

.history-pics-03{
    margin-top: 1rem;
    margin-left: -3rem;
    width: 300px;
    height: auto;
    box-shadow: 5px 3px 10px grey;
}

.history-pics-04{
    margin-top: 1rem;
    margin-left: -40px;
    transform: rotate(7deg);
    width: 300px;
    height: auto;
    box-shadow: 5px 3px 10px grey;
}

.history-pics-05{
    margin-top: 6rem;
    margin-left: -20px;
    transform: rotate(-10deg);
    width: 250px;
    height: 148px;
    box-shadow: 5px 3px 10px grey;
}

.history-pics-06{
    margin-top: 2rem;
    margin-bottom: 3rem;
    width: 360px;
    height: auto;
    box-shadow: 5px 3px 10px grey;
}

.img-comment{
    text-align: center;
    font-style: italic;
    font-size: 1.1rem;
}




.ps-carousel-container{
    position: relative;
    height: 400px;
    width: 100%;
    overflow: hidden;
    text-align: center; 
}

.ps-content-wrapper{
    width: 360px;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.logo-wrapper{
    /* border-style: solid;
    border-width: 2px;
    border-color: black; */
    width: 470px;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.text-content-ps{
    text-align: center;
    margin-top: 4rem;
    width: 360px;
    vertical-align: middle;
    vertical-align: -webkit-baseline-middle;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 2.7em;
}

.ps-bg-img-container{
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: 100%; 
    width: 100%;
    color: white;
    text-align: center;
    display: table;
  }

.ps-bg-text-container{
    
    height: 100%;
    width: 360px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10rem;
    background-color: black;
    overflow: hidden;
}

  .ps-bg-01 {
    background-image: url("https://strapi.australianpower.net/carousel/power-station.jpg");
  }

  .ps-mask {
  
    cursor: pointer;
    /* display: inline-block; */
    overflow: hidden;
    
    /* Background to mask */
    background-position: 0% 50%; 
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    
    /* Text size and style */
    font-size: 95px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -10px;
    padding: 0 3px;
    text-shadow:0px 0px 0px #fff;
    margin: 0;
  }

  .ps-photo-origin{
      font-size: 14px;
      font-style: italic;
      text-align: right;
      line-height: 150px;
      margin-right: 2rem;
  }

  .ps-link{
    color: rgb(241, 140, 24)
  }

  .ps-link:hover{
    color: rgb(248, 224, 87);
    text-decoration: none;
  }

  .ps-content-grid{
    margin-left: auto;
    margin-right: auto;
    width: 80rem;
    display: grid;
    grid-template-columns: auto auto;
  }

  .ps-photo-grid{
    width: 13rem;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: auto;
  }

  .ps-photo{
    width: 13rem;
    margin: 3px;
    border-style: solid;
    border-color: grey;
    border-width: 1px;
  }
.footer-wrapper{
    display: grid;
    width: 360px;
    grid-template-columns: auto;
    margin-left: auto;
    margin-right: auto;
}

.footer-container {
    font-family: 'Open Sans Condensed', sans-serif;
    width: 360px;
    text-align: center;
  }

.footer-data{
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgb(179, 176, 176);
    padding: 3px;
}

.company-name{
    font-size: 1.2rem;
    font-weight: 700;
    border-bottom-style: none;
}

.other-data{
    font-size: 0.9rem; 
}
.partner-icon{
    font-size: 7rem;
    margin-top: 0.3rem;
    margin-left: 0.8rem;
    padding: 10px; 
    transform: scale(1);
    transition: all 1s ease !important;
}

.partner-icon:hover{
    transform: scale(1.2);
}

.partner-icon-container{
    overflow: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 8rem;
    height: 6.9rem;
    border-style: none;
    align-content: center;
    
}

.Facebook-icon{
    color: rgb(42, 111, 238)
}

.Linkedin-icon{
    color: rgb(53, 171, 240)
}

.Google-icon{
    color: rgb(230, 52, 52)
}

.google-logo{
    width: 8rem;
    height: 6.9rem;
}

.partner-icon-label-container{
    font-family: 'Open Sans Condensed', sans-serif;
    text-align: center;
    font-size: 1.3rem;
    padding-top: 0;
    margin-top: 0;
}
.product-detail {
    width: 100%;
    
    /* max-width: 600px; 
    margin: 1rem;
    */
}

.product-detail .carousel .slide {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}


.product-carousel-image{
    width: 300px;
    height: 350px;
    object-fit: contain !important;
}



.enquire-label{
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 1.5rem;
}

.button-captcha-container{
    display: grid;
    grid-template-columns: auto auto;
}
/* .card .product-list {
    width: 20rem;
} */


.card-body {
    padding: 2rem 0 !important;
}

.card-grid{
    margin-left: auto;
    margin-right: auto;
    width: 350px;
    display: grid;
    grid-template-columns: 150px auto ;
}

.card-text {
    font-size: 0.9rem;
    padding: 0.4rem 1.9rem;
}

.container-fluid .row{
    padding-top:  0.7rem;
}

.overflow{
    overflow: hidden;
}

.apc-card-img {
    width: 150px;
    height: auto;
    transform: scale(1);
    transition: all 1s ease !important;
}

.apc-card-img:hover {
    transform: scale(1.5);
}

.apc-card-action-container{
    text-align: center;
    align-items: center;
}

.shortdesc-container {
    height: 6rem;
    overflow: hidden;
    margin-bottom: 1rem;
}

.product-title-container {
    margin-top: 1rem;
    height: 4rem;
    overflow: hidden;
    margin-bottom: .5rem;
}

.main-modal {
    min-width: 75%;
}

.row {
    display: flex;
  }
  
  /* Create two equal columns that sits next to each other */
  .column {
    flex: 50% 1;
    padding: 10px;
    
  }

  .carousel-container {
    border-width: 1px;
    border-color: darkgrey;
    align-items: center;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    /*box-shadow: 5px 5px 10px 1px rgba(0,0,0,0.253);*/
}

.email-price-row {
    display: table;
    width: inherit; /*Optional*/
    table-layout: fixed; /*Optional*/
    /*border-spacing: 10px; Optional*/
    margin-top: 1rem;
}

.email-price-column {
    display: table-cell;
}

.email-price-column-right {
    display: table-cell;
    float: right;
}

.btn-enquire{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #ed3330;
    padding: 15px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #fff;
    /* display: inline-block; */
    border: none;
    transition: all 0.4s ease 0s;
}

.btn-enquire:hover{
    background: #434343;
    border-width: 0px;
    letter-spacing: 1px;
    box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
    transition: all 0.4s ease 0s;
}

/* General button style */
.btn-enq {
    border: none;
    border-radius: 3px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.9rem;
    color: inherit;
    background: none;
    cursor: pointer;
    padding: 10px 60px;
    display: inline-block;
    margin: 5px 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    outline: none;
    position: relative;
    transition: all 0.3s;
}

.btn-enq:after {
    content: '';
    position: absolute;
    z-index: -1;
    transition: all 0.3s;
}

/* Pseudo elements for icons */
.btn-enq:before {
    font-family: 'FontAwesome';
    /* speak: none; */
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    position: relative;
    -webkit-font-smoothing: antialiased;
}


/* Icon separator */
.btn-sep {
    padding: 25px 60px 25px 120px;
}

.btn-sep:before {
    background: rgba(0,0,0,0.15);
}


.btn-4 {
    background: #34495e;
    color: #fff;
}

.btn-4:hover {
    background: #2c3e50;
}

.btn-4:active {
    background: #2c3e50;
    top: 2px;
}

.btn-4:before {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
}

/* Icons */

.icon-cart:before {
    content: "\f07a";
}

.icon-heart:before {
    content: "\f55a";
}

.icon-info:before {
    content: "\f05a";
}

.icon-send:before {
    content: "\f1d8";
}
/*.card-img-top .photo-header{
    height: 250px;
    width: 200px;
}*/

.emailform-container{
    padding: 10px;
}

.container-grid {
    display: block;
    padding-top: 3px;
  }

  .container-productlist-pageoptions {
    width: 100%;
    text-align: center;
    padding: 5px;
  }

  .container-paginator{
    display: inline-block;
    padding: 0;
    margin-right: 2rem;
  }
  
  .grid-item {
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    font-size: 30px; */
    width: 19em;
    /*height: 37rem;*/
    padding: 3px;
  }

  .pagination>li>a{
    padding: 0.75rem 1rem;
    min-width: 3.2rem;
    text-align: center;
    box-shadow: none !important;
    border-color: #ced4da !important;
    color: saturate(darken(#ced4da, 30%), 10%);
    font-weight: 900;
    font-size: 1rem;
  } 

  .pagination>li>a:active{
    color: saturate(darken(#ced4da, 50%), 5%) !important;
    background-color: saturate(lighten(#ced4da, 7.5%), 2.5%) !important;
    border-color: #ced4da !important;  
  }

  .pagination>li>a:hover{
    background-color: lighten(desaturate(#ced4da, 50%), 12.5%);
  }

  .pagination>ul{
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .aws-btn {
    font-family: Arial, Helvetica, sans-serif !important;
    }

  .container-productdetails-grid{
      display: grid;
      grid-template-columns: 350px;
      padding-top: 3px;
  }

  .details-container {
    margin-right: 3px;
    overflow: auto;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 1rem;
}

.price-container {
    /* background: #f12711;
    background: -webkit-linear-gradient(to right, #f5af19, #f12711);  
    background: linear-gradient(to right, #f5af19, #f12711);  */

    background: #BBD2C5;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #292E49, #536976, #BBD2C5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */



    color: #fefefe;
    text-align: center;
    font-family: 'Open Sans Condensed', sans-serif;
    /*font-family: Impact, Charcoal, sans-serif;*/
    font-size: 1.3rem;
    font-weight: bolder;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 5.2rem;
    padding-top: 3px;
    /*box-shadow: 5px 5px 10px 1px rgba(0,0,0,0.253);*/
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
}

.productlist-wrapper{
    display: grid;
    grid-template-columns: auto;
}

.productlist-search-container{
    margin-top: 1rem;
    border-style: none;
    border-width: 0;
    display: grid;
    grid-template-columns: auto;
}

.productdetail-url {
    font-size: 1.3rem;
    color: rgb(37, 125, 240);
}

/* 
Sale Ribbon
*/

.wdp-ribbon{
	display: inline-block;
    padding: 2px 15px;
	position: absolute;
    left: 0px;
    top: 3px;
    line-height: 24px;
	height:28px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
	border-radius: 0;
    text-shadow: none;
    font-weight: normal;
    background-color: #ff7010 !important;
    z-index: 3;
    color: #ffffff;
    font-weight: bolder;
}

.wdp-ribbon-two:before, .wdp-ribbon-two:before {
    display: inline-block;
    content: "";
    position: absolute;
    left: -14px;
    top: 0;
    border: 9px solid transparent;
    border-width: 14px 8px;
    border-right-color: #ff7010 ;
}
.wdp-ribbon-two:before {
    border-color: #ff7010 ;
    border-left-color: transparent!important;
    left: -9px;
}

.wdp-ribbon-four{
	left: -1px;
    top: 3px;
}

.prev-price{
    font-weight: lighter;
    font-style: italic;
    text-decoration: line-through;
    color: dimgray;
    font-size: 0.85rem;
}
.modal-title{
    font-family: 'Open Sans Condensed', sans-serif;
}

ul.pagination{margin-top:0;margin-bottom:0;box-shadow:0 0 5px rgba(0,0,0,0.1)}ul.pagination li.page-item.active a.page-link{color:#445565 !important;background-color:#e3e7eb !important;border-color:#ced4da !important}ul.pagination a.page-link{padding:0.75rem 1rem;min-width:3.5rem;text-align:center;box-shadow:none !important;border-color:#ced4da !important;color:#6b88a4;font-weight:900;font-size:1rem}ul.pagination a.page-link:hover{background-color:#f4f4f4}

.apc-checkbox-container{
    padding: 2px !important;
    cursor: pointer;
}

.apc-checkbox-container:hover{
    font-weight: bold;
    cursor: pointer;
}

.apc-textbox-container{
    padding: 0px !important;
}

.apc-textbox{
    padding: 1px !important;
    width: 45px !important;
    border-color: rgb(129, 129, 129);
}

.apc-categories-container{
   margin-top: .8rem;
   width:340px;
   height:180px;
   margin-left: .7rem;
   padding: 1px !important; 
}

.apc-categories-header{
    font-size: 1.1rem;

}
.product-card-container {
  width: 360px;
  height: 604px;
  position: relative;
  background-color: #41475a;
}

.card-container {
  position: absolute;
  left: 35px;
  top: 44px;
  width: 290px;
  height: 516px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 1px 2px 5px #292c34;
}

.top-card-photo-container {
  position: relative;
  width: 290px;
  height: 271px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.product-img {
  z-index: 2;
  position: relative;
  top: 0px;
  left: 0px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 290px;
  height: 271px;
  object-fit: fill;
}

.bottom-card-details-container {
  position: relative;
  width: 290px;
  height: 245px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #e77129;
  color: #ffffff;

  text-align: center;
}

.productcardv2-price-container {
  position: absolute;
  top: 246px;
  left: 47px;
  width: 197px;
  height: 49px;
  border-radius: 10px;
  border-width: 1;
  border-style: solid;
  border-color: #e9572b;
  /*background-color: #facf61;*/
  background-image: linear-gradient(to right, #facf61, #fdebb7);
  box-shadow: 1px 2px 5px;
  z-index: 2;
  text-align: center;
}

.price-text {
  font-family: "Karantina";
  font-size: 48px;
  color: #000000;
  margin: auto;
  top: -18px;
  width: auto;
  position: relative;
}

.extgst-text {
  font-family: "Karantina";
  font-size: 24px;
  font-weight: 100;
  color: #000000;
  margin: auto;
  width: 41px;
  position: absolute;
  top: 5px;
  right: 1px;
}

.title-text {
  font-family: "Marcellus";
  font-size: 18px;
  width: 277px;
  height: auto;
  position: absolute;
  top: 39px;
  left: 6px;
  line-height: 1;
}

.subtitle-text {
  font-family: "Work Sans";
  font-weight: 100;
  font-size: 18px;
  width: 240px;
  height: 130px;
  position: absolute;
  top: 105px;
  left: 25px;
  overflow-y: hidden;
  line-height: 1.2;
}

.side_drawer {
    height: 100%;
    background-color: #353c4a;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 300px;
    z-index: 100;
    text-align: left;
}

.apc-link {
    color: black !important;
    font-size: 1.1rem;
    transform: scale(1);
    transition: all 2s ease !important;
}

.apc-link-container{
    width: 100%;
    background-color: transparent;
    transform: scale(1);
    transition: all 0.7s ease !important;
}

.apc-link-container:hover{
    transform: scale(1.2);
}

.apc-link:hover {
    text-decoration: none;
}

.apc-link:visited{
    font-weight: bold;
    color: black !important;
}
.side_drawer {
    height: 100%;
    background-color: #353c4a;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}

.side_drawer.open {
    top: 0;
    left: 0;
    transform: translateX(0);
}

.side_drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.side_drawer li {
    margin: 0.7rem 0;
}

.side_drawer a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 400;
}

.side_drawer a:hover,
.side_drawer a:active {
    color: #fa923f;
}

.drawer_logo_image{
    width: 116px;
    height: 100px;
    /* animation: rotation 8s infinite linear; */
}

.drawer_logo_image:hover{
    -webkit-animation: rotation 8s infinite linear;
            animation: rotation 8s infinite linear;
}


.drawer_logo_container {
    width: 100%;
    max-width: 400px;
    text-align: center;
}
.contacts-wrapper-grid{
    width: 360px;
    display: grid;
    grid-template-columns: auto;
    font-family: 'Open Sans Condensed', sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
       -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
            text-size-adjust: 100%;
    margin: 0px;
    padding: 0px;
}

.contactus-container{
    margin-top: 1rem;
    margin-left: .5rem;
    padding: .5rem;
    width: 345px;
    
    border-style: solid;
    border-width: 0.7px;
    border-color: rgb(172, 169, 169);
    border-radius: 5px;
    background-color: whitesmoke;
    text-align: center;

}

.contact-cards-wrapper{
    margin: 0px;
    padding: 0px;
    display: grid;
    grid-template-columns: auto auto;
}

.contact-card-container{
    padding: 2px;
}

.contacts-name-box{
    background-color: rgb(243, 116, 65);
    text-align: center;
}

.contacts-header-title{
    text-align: center;
    font-size: 1.7rem;
    font-weight: 400;
    font-family: 'Open Sans Condensed', sans-serif;
}

.contacts-name{
    font-size: 1.2rem;
    font-weight: 400;
    color: whitesmoke;
}

.contacts-position{
    font-size: 1.2rem;
    font-weight: 400;
    color: rgb(113, 123, 156);
    text-align: center;
    line-height: 12px !important;
}

.contacts-details{
    font-size: 1rem;
    color: #000000;
    text-align: center;
}

.contacts-email{
    font-size: .7rem;
    color: #0a40a3;
    text-align: center;
}
.woyselect-filter-container {
  position: relative;
  width: 360px;
  height: 74px;
  background-color: transparent;
  z-index: 4;
}

.woyselect-filter-button {
  font-family: "Work Sans";
  font-size: 15px;
  color: black;
  /*background-color: #e3e3e3;*/
  background-image: linear-gradient(to right, #dddbdb, #f0eeee);
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c9c9c9;
  width: 290px;
  height: 50px;
  position: absolute;
  top: 12px;
  left: 35px;
}

.woyselect-select-overlay {
  width: 100%;
  height: 100%;
  position: relative;
  left: 0px;
  top: 13px;
  opacity: 0%;
  z-index: 3;
}

.woyselect-browse-icon {
  position: absolute;
  top: 15px;
  left: 10px;
  text-align: left;
  font-size: 17px;
  color: #e77129;
}

.woyselect-browse-text {
  position: absolute;
  top: 15px;
  left: 25px;
  text-align: left;
  cursor: pointer;
}

.category-frame {
  width: 360px;
  height: 302px;
  background-color: #e77129;
  z-index: 1;
  overflow: hidden;
  position: relative;
}

.category-card {
  position: relative;
  top: 26px;
  left: 66px;
  width: 228px;
  height: 250px;
  border-radius: 20px;
  z-index: 2;
  background-color: #8b8383ce;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
}

.category-card-top-shade {
  margin: auto;
  width: 228px;
  height: 100px;
  background-color: #292c34;
  opacity: 0.8;
  z-index: 3;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.category-card-title-yellow {
  font-family: "Work Sans";
  font-weight: 800;
  color: #fae05c;
  font-size: 25px;
}

.category-card-title-white {
  font-family: "Work Sans";
  font-weight: 800;
  color: #ffffff;
  font-size: 32px;
}

.category-card-subtitle {
  font-family: "Work Sans";
  font-weight: 300;
  color: #fae05c;
  font-size: 16px;
}

.category-card-text-container {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 100px;
  line-height: 0.8;
  margin-top: 10px;
}

.category-card-bg-img {
  z-index: 2;
  position: relative;
  top: 0px;
  left: 0px;
  border-radius: 20px;
  width: 228px;
  height: 250px;
  object-fit: fill;
}

.categorypanel-grid {
  width: 2160px;
  height: 302px;
  display: grid;
  grid-template-columns: 6;
  grid-template-rows: 1;
  grid-auto-flow: column;
  grid-auto-columns: minmax(360px);
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.categorypanel-grid::-webkit-scrollbar {
  display: none;
}

.categorypanel-frame {
  width: 360px;
  height: 302px;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
}

.categorypanel-frame::-webkit-scrollbar {
  display: none;
}

.postboard-container {
  width: 360px;
  height: auto;
}

.postboard-frame {
  width: 360px;
  height: 302px;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.postboard-frame::-webkit-scrollbar {
  display: none;
}

.powerforrent-container {
  position: relative;
  width: 360px;
  height: 302px;
}

.powerforrent-contact-text {
  font-family: "Work Sans";
  font-weight: 800;
  font-size: 9px;
  width: auto;
  height: auto;
  position: absolute;
  top: 18px;
  left: 287px;
  color: #000000;
  z-index: 2;
}

.powerforrent-email-text {
  font-family: "Work Sans";
  font-weight: 700;
  font-size: 9px;
  width: auto;
  height: auto;
  position: absolute;
  top: 40px;
  left: 228px;
  color: #000000;
  z-index: 2;
}

.powerforrent-phone1-text {
  font-family: "Work Sans";
  font-weight: 700;
  font-size: 10px;
  width: auto;
  height: auto;
  position: absolute;
  top: 48px;
  left: 293px;
  color: #e77129;
  z-index: 2;
}

.powerforrent-phone2-text {
  font-family: "Work Sans";
  font-weight: 700;
  font-size: 10px;
  width: auto;
  height: auto;
  position: absolute;
  top: 56px;
  left: 276px;
  color: #e77129;
  z-index: 2;
}

.powerforrent-title-text {
  font-family: "Work Sans";
  font-weight: 800;
  font-size: 18px;
  color: #000000;
  line-height: 0.8;
  width: auto;
  height: auto;
  position: absolute;
  top: 106px;
  left: 226px;
  z-index: 2;
}

.powerforrent-subtitle-text {
  font-family: "Work Sans";
  font-weight: 700;
  font-size: 11px;
  color: #000000;
  text-align: left;
  line-height: 1.1;
  width: auto;
  height: auto;
  position: absolute;
  top: 161px;
  left: 239px;
  z-index: 2;
}

.powerforrent-footer-text {
  font-family: "Work Sans";
  font-weight: 700;
  font-size: 10px;
  color: #000000;
  width: 350px;
  height: auto;
  position: absolute;
  top: 270px;
  left: 6px;
  text-align: center;
  z-index: 2;
}

.powerforrent-img {
  width: 360px;
  height: 302px;
  object-fit: fill;
  z-index: 1;
}

.moved-container {
  position: relative;
  width: 360px;
  height: 302px;
}

.moved-img {
  width: 360px;
  height: 302px;
  object-fit: fill;
  z-index: 1;
}

.maintext-container {
  position: absolute;
  top: 51px;
  left: 21px;
  width: auto;
  height: auto;
  line-height: 0.8;
  z-index: 2;
  text-align: center;
}

.addresstext-container {
  position: absolute;
  top: 156px;
  left: 23px;
  width: auto;
  height: auto;
  line-height: 0.8;
  z-index: 2;
  text-align: left;
}

.companytext-container {
  position: absolute;
  top: 230px;
  left: 21px;
  width: auto;
  height: auto;
  line-height: 0.8;
  z-index: 2;
  text-align: left;
}

.main-text {
  font-family: "JetBrains Mono";
  font-weight: 800;
  color: #000000;
  font-size: 38px;
}

.address-text {
  font-family: "Karantina";
  font-weight: 300;
  color: #0e2a49;
  font-size: 38px;
}

.company-text {
  font-family: "Karantina";
  font-weight: 400;
  color: #e77129;
  font-size: 25px;
}

section {
  display: block;
}

.section.is-medium {
  padding: 0 0 0 0;
}
.wrapper-content {
  width: 100%;
  background-color: ivory;
  min-height: 30rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
  display: block;
}

.content-grid {
  display: grid;
  grid-template-columns: 360px;
}

.container-text {
  width: inherit;
  vertical-align: middle;
  display: inline-block;
  margin-left: 4rem;
  margin-right: 2rem;
  text-align: center;
}

.container-profilepic {
  display: inline-block;
  margin-right: 2rem;
}

.text-title {
  margin-top: 4rem;
  vertical-align: middle;
  vertical-align: -webkit-baseline-middle;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 2rem;
  text-align: center;
}

.text-content {
  margin-top: 4rem;
  vertical-align: middle;
  vertical-align: -webkit-baseline-middle;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1.5rem;
  text-align: center;
}

.text-content-sub {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1rem;
}

.text-content-name {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1.5rem;
}

.name-container {
  text-align: center;
}

.profilepic {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  border: 8px solid #fa923f;
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.container-category {
  /* border-style: solid;
    border-width: 1px;
    border-color: tomato; */

  margin-top: 0.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.container-category-cards {
  /*border-style: solid;
    border-width: 1px;
    border-color: rgb(51, 113, 207);*/

  display: grid;
  grid-template-columns: auto auto;
  padding: 0;
}

.container-category-cell {
  padding: 10px !important;
}

.apc-contact-container {
  margin-top: 4rem;
}

.homepagev2-container {
  width: 360px;
  height: auto;
  overflow: hidden;
  position: relative;
}

.category-ribbon {
  position: absolute;
  width: 220px;
  height: 45px;
  background-color: #eb4411;
  font-family: "Work Sans";
  font-weight: 800;
  font-size: 22px;
  color: #ffffff;
  transform: rotate(-35deg);
  z-index: 4;
  left: -32px;
  top: 15px;
}

.category-text {
  width: auto;
  height: auto;
  position: absolute;
  top: 7px;
  left: 32px;
}

html{
  height: 100%;
}

body {
  height: 100%;
}

a {
    text-decoration: none;
  }
  
  h1 {
    font-family: Staatliches;
    font-size: 120px;
  }
  
  #category {
    font-family: Staatliches;
    font-weight: 500;
  }
  
  #title {
    letter-spacing: 0.4px;
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 1.13636;
  }
  
  #banner {
    margin: 20px;
    height: 800px;
  }
  
  #editor {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.75;
  }
  
  .uk-navbar-container {
    background: #fff !important;
    font-family: Staatliches;
  }
  
  img:hover {
    opacity: 1;
    transition: opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  
