@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;800&display=swap");

.category-frame {
  width: 360px;
  height: 302px;
  background-color: #e77129;
  z-index: 1;
  overflow: hidden;
  position: relative;
}

.category-card {
  position: relative;
  top: 26px;
  left: 66px;
  width: 228px;
  height: 250px;
  border-radius: 20px;
  z-index: 2;
  background-color: #8b8383ce;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
}

.category-card-top-shade {
  margin: auto;
  width: 228px;
  height: 100px;
  background-color: #292c34;
  opacity: 0.8;
  z-index: 3;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.category-card-title-yellow {
  font-family: "Work Sans";
  font-weight: 800;
  color: #fae05c;
  font-size: 25px;
}

.category-card-title-white {
  font-family: "Work Sans";
  font-weight: 800;
  color: #ffffff;
  font-size: 32px;
}

.category-card-subtitle {
  font-family: "Work Sans";
  font-weight: 300;
  color: #fae05c;
  font-size: 16px;
}

.category-card-text-container {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 100px;
  line-height: 0.8;
  margin-top: 10px;
}

.category-card-bg-img {
  z-index: 2;
  position: relative;
  top: 0px;
  left: 0px;
  border-radius: 20px;
  width: 228px;
  height: 250px;
  object-fit: fill;
}

.categorypanel-grid {
  width: 2160px;
  height: 302px;
  display: grid;
  grid-template-columns: 6;
  grid-template-rows: 1;
  grid-auto-flow: column;
  grid-auto-columns: minmax(360px);
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.categorypanel-grid::-webkit-scrollbar {
  display: none;
}

.categorypanel-frame {
  width: 360px;
  height: 302px;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
}

.categorypanel-frame::-webkit-scrollbar {
  display: none;
}
