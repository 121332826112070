@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");
.woyselect-filter-container {
  position: relative;
  width: 360px;
  height: 74px;
  background-color: transparent;
  z-index: 4;
}

.woyselect-filter-button {
  font-family: "Work Sans";
  font-size: 15px;
  color: black;
  /*background-color: #e3e3e3;*/
  background-image: linear-gradient(to right, #dddbdb, #f0eeee);
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c9c9c9;
  width: 290px;
  height: 50px;
  position: absolute;
  top: 12px;
  left: 35px;
}

.woyselect-select-overlay {
  width: 100%;
  height: 100%;
  position: relative;
  left: 0px;
  top: 13px;
  opacity: 0%;
  z-index: 3;
}

.woyselect-browse-icon {
  position: absolute;
  top: 15px;
  left: 10px;
  text-align: left;
  font-size: 17px;
  color: #e77129;
}

.woyselect-browse-text {
  position: absolute;
  top: 15px;
  left: 25px;
  text-align: left;
  cursor: pointer;
}
