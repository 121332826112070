@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap');
.contacts-wrapper-grid{
    width: 360px;
    display: grid;
    grid-template-columns: auto;
    font-family: 'Open Sans Condensed', sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    margin: 0px;
    padding: 0px;
}

.contactus-container{
    margin-top: 1rem;
    margin-left: .5rem;
    padding: .5rem;
    width: 345px;
    
    border-style: solid;
    border-width: 0.7px;
    border-color: rgb(172, 169, 169);
    border-radius: 5px;
    background-color: whitesmoke;
    text-align: center;

}

.contact-cards-wrapper{
    margin: 0px;
    padding: 0px;
    display: grid;
    grid-template-columns: auto auto;
}

.contact-card-container{
    padding: 2px;
}

.contacts-name-box{
    background-color: rgb(243, 116, 65);
    text-align: center;
}

.contacts-header-title{
    text-align: center;
    font-size: 1.7rem;
    font-weight: 400;
    font-family: 'Open Sans Condensed', sans-serif;
}

.contacts-name{
    font-size: 1.2rem;
    font-weight: 400;
    color: whitesmoke;
}

.contacts-position{
    font-size: 1.2rem;
    font-weight: 400;
    color: rgb(113, 123, 156);
    text-align: center;
    line-height: 12px !important;
}

.contacts-details{
    font-size: 1rem;
    color: #000000;
    text-align: center;
}

.contacts-email{
    font-size: .7rem;
    color: #0a40a3;
    text-align: center;
}