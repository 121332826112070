.container-app {
  /*height: 100%;*/
  width: 360px;
  margin-left: auto;
  margin-right: auto;
  /* display: flex;
  flex-flow: column; */
}

.container-body {
  background: radial-gradient(#e5e5e5,#ffffff, #888585);
  width: 100%;
  height: 100%;
  padding: 0 0 0 0;
  /* overflow:auto; */
}

.container-footer {
  /*display: inline-flexbox;*/
  background-color:rgb(221, 217, 216);
  min-height: 9rem;
  width: 100%;
  padding: 10px 0 0 0;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #fa923f rgba(0,0,0,0.5);
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 9px;
}
*::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.5);
}
*::-webkit-scrollbar-thumb {
  background-color: #fa923f;
  border: 1px solid rgba(0,0,0,0.5);
}