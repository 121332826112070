@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap");
section {
  display: block;
}

.section.is-medium {
  padding: 0 0 0 0;
}
.wrapper-content {
  width: 100%;
  background-color: ivory;
  min-height: 30rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  display: block;
}

.content-grid {
  display: grid;
  grid-template-columns: 360px;
}

.container-text {
  width: inherit;
  vertical-align: middle;
  display: inline-block;
  margin-left: 4rem;
  margin-right: 2rem;
  text-align: center;
}

.container-profilepic {
  display: inline-block;
  margin-right: 2rem;
}

.text-title {
  margin-top: 4rem;
  vertical-align: middle;
  vertical-align: -webkit-baseline-middle;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 2rem;
  text-align: center;
}

.text-content {
  margin-top: 4rem;
  vertical-align: middle;
  vertical-align: -webkit-baseline-middle;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1.5rem;
  text-align: center;
}

.text-content-sub {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1rem;
}

.text-content-name {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1.5rem;
}

.name-container {
  text-align: center;
}

.profilepic {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  border: 8px solid #fa923f;
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.container-category {
  /* border-style: solid;
    border-width: 1px;
    border-color: tomato; */

  margin-top: 0.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.container-category-cards {
  /*border-style: solid;
    border-width: 1px;
    border-color: rgb(51, 113, 207);*/

  display: grid;
  grid-template-columns: auto auto;
  padding: 0;
}

.container-category-cell {
  padding: 10px !important;
}

.apc-contact-container {
  margin-top: 4rem;
}

.homepagev2-container {
  width: 360px;
  height: auto;
  overflow: hidden;
  position: relative;
}

.category-ribbon {
  position: absolute;
  width: 220px;
  height: 45px;
  background-color: #eb4411;
  font-family: "Work Sans";
  font-weight: 800;
  font-size: 22px;
  color: #ffffff;
  transform: rotate(-35deg);
  z-index: 4;
  left: -32px;
  top: 15px;
}

.category-text {
  width: auto;
  height: auto;
  position: absolute;
  top: 7px;
  left: 32px;
}
