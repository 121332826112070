@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@800&family=Karantina:wght@300;400&display=swap");
.moved-container {
  position: relative;
  width: 360px;
  height: 302px;
}

.moved-img {
  width: 360px;
  height: 302px;
  object-fit: fill;
  z-index: 1;
}

.maintext-container {
  position: absolute;
  top: 51px;
  left: 21px;
  width: auto;
  height: auto;
  line-height: 0.8;
  z-index: 2;
  text-align: center;
}

.addresstext-container {
  position: absolute;
  top: 156px;
  left: 23px;
  width: auto;
  height: auto;
  line-height: 0.8;
  z-index: 2;
  text-align: left;
}

.companytext-container {
  position: absolute;
  top: 230px;
  left: 21px;
  width: auto;
  height: auto;
  line-height: 0.8;
  z-index: 2;
  text-align: left;
}

.main-text {
  font-family: "JetBrains Mono";
  font-weight: 800;
  color: #000000;
  font-size: 38px;
}

.address-text {
  font-family: "Karantina";
  font-weight: 300;
  color: #0e2a49;
  font-size: 38px;
}

.company-text {
  font-family: "Karantina";
  font-weight: 400;
  color: #e77129;
  font-size: 25px;
}
